import React from "react"
import {Navbar,NavbarToggler,Collapse,Nav,NavItem} from 'reactstrap'
import { Link } from "gatsby"

import Logo from '../images/logo-light.svg'


class Header extends React.Component{
     constructor(props) {
          super(props);
          this.toggle = this.toggle.bind(this);
          this.state = {
           isOpen: false,
          };
     }
     toggle() {
          this.setState({
           isOpen: !this.state.isOpen
          });
     }
     render(){
          return(
            <header>
               <Navbar light fixed="top" expand="lg" className="navbar-white bg-white">
                    <div className="container">
                      <Link to="/" className="navbar-brand"><Logo alt="CaringBand" /></Link>
                      {false&&<NavbarToggler onClick={this.toggle} />}
                      {false&&<Collapse isOpen={this.state.isOpen} navbar>
                         <Nav className="ml-auto pt-3 pb-3" navbar>
                          <NavItem><Link to="/about" className="text-navbar nav-link">About</Link></NavItem>
                          <NavItem><Link to="/testimonials" className="text-navbar nav-link">Testimonials</Link></NavItem>
                          <NavItem><Link to="/faq" className="text-navbar nav-link">FAQ</Link></NavItem>
                          <NavItem><Link to="/#contact" className="text-navbar nav-link">Contact</Link></NavItem>
                          <NavItem className="ml-4"><Link to="https://shop.caringband.com/" className="btn btn-primary text-white">Shop Now</Link></NavItem>
                        </Nav>
                      </Collapse>}
                 </div>
               </Navbar>
            </header>
          )
     }
}
export default Header
